<template>
  <div class="dmp-main">
    <div class="local-loader-container" v-show="loader">
      <div class="inava-loader"></div>
    </div>
    <div v-show="!loader">
      <NestedTopNavigation v-if="!fullscreenActive" />
      <router-view />
    </div>
  </div>
</template>
<script>
import NestedTopNavigation from '@/components/Common/Navigation/NestedTopNavigation.vue'
import { mapState } from 'vuex'

export default {
  name: 'DMPMain',
  components: { NestedTopNavigation },

  props: {
    theme: String
  },

  data() {
    return {
      loader: true
    }
  },

  computed: {
    ...mapState('common', ['isCollapsed', 'reportFullscreen']),
    fullscreenActive() {
      return this.reportFullscreen.status
    }
  },

  created() {
    this.loader = true

    // remove if the stylesheet is present
    const isLinkPresent = document.getElementsByTagName('link')
    for (let i = 0; i < isLinkPresent.length; i++) {
      const linkHref = isLinkPresent[i].getAttribute('href')
      if (linkHref === '/kendoLight.css' || linkHref === '/kendoDark.css') {
        isLinkPresent[i].remove()
      }
    }

    // add the require stylesheet
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.type = 'text/css'
    if (this.theme === 'light') {
      link.href = process.env.VUE_APP_KENDO_LIGHT_CSS
    } else if (this.theme === 'dark') {
      link.href = process.env.VUE_APP_KENDO_DARK_CSS
    }
    // loader set to false when stylesheet loaded
    link.onload = () => {
      this.loader = false
    }
    document.head.appendChild(link)
  },

  methods: {
    onSelectMenu(e) {
      if (e.item.pathName !== 'entities') {
        this.$router.push({ name: e.item.pathName })
      } else {
        this.$router.push({
          name: e.item.pathName,
          // Giving static value for now later it will be removed
          params: { sgaId: 'SGAUK00000' }
        })
      }
    },

    // responsible for applying CSS styles to the active
    // navigation link in the menu.
    applyCssToActiveNavLink() {
      // remove the active link style from prev active link
      const prevActiveNavLink =
        document.getElementsByClassName('active-nav-link')

      const prevActiveNavLinkList =
        Array.prototype.slice.call(prevActiveNavLink)

      if (prevActiveNavLink.length > 0) {
        prevActiveNavLinkList.forEach((activeNavLink) =>
          activeNavLink.classList.remove('active-nav-link')
        )
      }

      // adding the active link style to current active link
      const activeNavLink = document.querySelectorAll('li[tabindex="0"]')
      activeNavLink[0].classList.add('active-nav-link')
    },

    // making the current menu item active in the
    // navigation menu.
    makeMenuItemActive(itemName) {
      // remove the default active link
      const defaultNavLink = document.querySelectorAll('li[tabindex="0"]')
      defaultNavLink[0].setAttribute('tabindex', '-1')

      // making the current nav link active
      const currentNavLink = document.querySelectorAll(
        `li[aria-label="${itemName}"]`
      )
      currentNavLink[0].setAttribute('tabindex', '0')
    }
  }
}
</script>
<style lang="scss" scoped>
.dmp-main {
  .top-menu {
    background: var(--dmp-menu-bg-color);
    filter: drop-shadow(0 3px 6px #00000029);
    height: 60px;
    color: var(--dmp-menu-text-color);
  }
}
</style>
<style lang="scss">
.dmp-main {
  .k-menu-bar,
  .k-menu {
    font-size: 20px;
    height: 60px;
    max-width: 1450px;
    margin: 0 auto;
    font-weight: normal;
  }
  .k-animation-container {
    width: 100%;
  }

  .k-menu:not(.k-context-menu) > .k-item {
    color: #000000;

    &:hover {
      color: #ffffff;
      background-color: var(--dmp-menu-hover-bg-color);
    }
  }

  .k-menu:not(.k-context-menu) > .k-item.active-nav-link {
    color: #ffffff;
    background-color: var(--dmp-menu-hover-bg-color);
  }
}
</style>
