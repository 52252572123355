import { render, staticRenderFns } from "./NestedTopNavigation.vue?vue&type=template&id=1a2e4be2&scoped=true&"
import script from "./NestedTopNavigation.vue?vue&type=script&lang=js&"
export * from "./NestedTopNavigation.vue?vue&type=script&lang=js&"
import style0 from "./NestedTopNavigation.vue?vue&type=style&index=0&id=1a2e4be2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a2e4be2",
  null
  
)

export default component.exports